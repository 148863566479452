import { gql, useQuery } from '@apollo/client';
import { dropNullProps } from '../../../utils';

export const LIST_INVOICES = gql`
  query invoices($serviceStartDate: Date, $serviceEndDate: Date) {
    invoices: listInvoices(serviceStartDate: $serviceStartDate, serviceEndDate: $serviceEndDate) {
      id
    }
  }
`;

export default function useListInvoices(
  serviceStartDate = null,
  serviceEndDate = null,
  onCompleted = () => {},
  onError = () => {}
) {
  const { data, loading, error } = useQuery(LIST_INVOICES, {
    variables: dropNullProps({ ...serviceStartDate, ...serviceEndDate }),
    onCompleted,
    onError
  });

  return {
    allInvoiceIds: data?.invoices?.map(({ id }) => id),
    data,
    loading,
    error
  };
}
