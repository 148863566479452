import React, { useState } from 'react';
import { BoxContainer, FormSection, TrGraphQLErrors } from '../../../components';
import { CustomInput } from 'reactstrap';
import { TrLoader, TrPlaceholder, RenderData, Td, Tr, Loader } from '@oforce/global-components';
import { Table } from '../../../components/table-2.0';

export default function BulkDownloadDocumentsDocsSelection({ formik, documents, loading, error, clientId, policyId }) {
  const colSpan = 3;
  const initialToggleState = !!formik?.values?.documentIds.length;
  const [selectAll, setSelectAll] = useState(initialToggleState);
  const isClientContext = !!clientId;

  const filteredDocuments = documents?.filter(document =>
    Object.keys(formik?.values?.documentInsuredsMapping)?.includes(document?.id)
  );

  const filteredDocumentIds = filteredDocuments?.map(document => document?.id);

  const toggleSelectAll = selectAll => {
    if (!selectAll) {
      const allValidInsuredIds = formik?.values?.allValidInsuredIds;
      formik?.setFieldValue('documentIds', filteredDocumentIds);
      formik?.setFieldValue('insuredIdsFromChosenDocuments', allValidInsuredIds);
    } else {
      formik?.setFieldValue('documentIds', []);
      formik?.setFieldValue('insuredIdsFromChosenDocuments', []);
    }
    setSelectAll(!selectAll);
  };

  const addDocumentInsuredIds = checkDocumentId => {
    const { allValidInsuredIds, documentInsuredsMapping, insuredIds } = formik?.values;
    const insuredIdsFromChosenDoc = documentInsuredsMapping[checkDocumentId];
    const insuredIdsFromChosenDocs = [...insuredIdsFromChosenDoc, ...insuredIds];
    const validInsuredIds = insuredIdsFromChosenDocs.filter(id => allValidInsuredIds.includes(id));
    formik?.setFieldValue('insuredIds', unique(validInsuredIds));
    formik?.setFieldValue('insuredIdsFromChosenDocuments', unique(insuredIdsFromChosenDocs));
  };

  const removeDocumentInsuredIds = checkDocumentId => {
    const { allValidInsuredIds, documentInsuredsMapping } = formik?.values;

    const insuredIdsFromChosenDocs = filteredDocumentIds?.reduce((acc, documentId) => {
      if (documentId === checkDocumentId) return acc;
      const documentInsuredIds = [...acc, ...documentInsuredsMapping[documentId]];
      return [...new Set(documentInsuredIds)];
    }, []);

    const validInsuredIds = insuredIdsFromChosenDocs.filter(insuredId => allValidInsuredIds.includes(insuredId));
    formik?.setFieldValue('insuredIds', unique(validInsuredIds));
    formik?.setFieldValue('insuredIdsFromChosenDocuments', unique(insuredIdsFromChosenDocs));
  };

  const unique = ids => [...new Set(ids)];

  if (loading) return <Loader />;

  return (
    <FormSection title="Select Documents">
      <BoxContainer>
        <Table responsive>
          <thead>
            <tr>
              <th>
                <CustomInput
                  id="toggleSelectAllDocumentIds"
                  type="checkbox"
                  checked={selectAll}
                  onChange={() => toggleSelectAll(selectAll)}
                  className="mb-1"
                />
              </th>
              <th>Document Name</th>
              <th>File Type</th>
            </tr>
          </thead>
          <tbody>
            <RenderData
              data={filteredDocuments}
              loading={loading}
              error={error}
              loadingComponent={<TrLoader colSpan={colSpan} />}
              errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
              placeholderComponent={<TrPlaceholder colSpan={colSpan}>No documents</TrPlaceholder>}
            >
              {documents =>
                documents?.map((document, i) => (
                  <Tr
                    key={i}
                    style={formik?.values?.documentIds?.includes(document?.id) ? { backgroundColor: '#e6eaf2' } : {}}
                  >
                    <Td>
                      <CustomInput
                        id={document?.name}
                        type="checkbox"
                        checked={formik?.values?.documentIds?.includes(document?.id)}
                        onChange={() => {
                          if (formik?.values?.documentIds?.includes(document?.id)) {
                            formik?.setFieldValue(
                              'documentIds',
                              formik?.values?.documentIds.filter(documentId => documentId !== document?.id)
                            );
                            removeDocumentInsuredIds(document?.id);
                          } else {
                            formik?.setFieldValue('documentIds', [...formik?.values?.documentIds, document?.id]);
                            addDocumentInsuredIds(document?.id);
                          }
                        }}
                        className="mb-1"
                      />
                    </Td>
                    <Td>{document?.name}</Td>
                    <Td>
                      {isClientContext
                        ? document?.document?.filename?.split('.').pop().toUpperCase()
                        : document?.filename?.split('.').pop().toUpperCase()}
                    </Td>
                  </Tr>
                ))
              }
            </RenderData>
          </tbody>
        </Table>
      </BoxContainer>
    </FormSection>
  );
}
