import React, { useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { CustomDropdownItem } from '@oforce/global-components';
import { useDownloadDocument, useToggle } from '../../../hooks';
import { SendDocumentEmailModal, ShowDocumentDownloadModal, ShowDocumentPreviewModal } from '.';
import { HideFromRolesAndTypes } from '../../../shared/activeAccount/components';
import { useActiveAccount } from '../../../shared/activeAccount';
import { ActionDropdown, Render } from '../../../components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { SERVER_SIDE_DATE_FORMAT } from '../../../utils/dates';
import { useBulkDownloadDocumentsJobContext } from '../../../context';

const DocumentMutation = gql`
  mutation DocumentMutation($activeOn: Date, $documentRelationshipId: ID, $exposureDocumentId: ID) {
    document: populateDocument(
      exposureDocumentId: $exposureDocumentId
      documentRelationshipId: $documentRelationshipId
      activeOn: $activeOn
    ) {
      url
      filename
    }
  }
`;

export default function DocumentActions({ document, activeOn, showVersions = true, showActiveOn = true, redirectUrl }) {
  const { insured } = document;
  const history = useHistory();
  const { download } = useDownloadDocument();
  const { startBulkDownloadDocumentsJob, loading, error } = useBulkDownloadDocumentsJobContext();
  const [showEmailDocumentModal, toggleEmailDocumentModal] = useToggle();
  const [showDocumentPreviewModal, toggleDocumentPreviewModal] = useToggle();
  const [showDocumentDownloadModal, toggleDocumentDownloadModal] = useToggle();
  const isDocumentRelationship = document.source === 'DOCUMENT_RELATIONSHIP';

  const {
    ROLES: { OBSERVER },
    TYPES: { CLAIMS_ADJUSTER }
  } = useActiveAccount();

  const goToVersions = () => {
    if (!!document?.documentRelationshipId) {
      history.push(`/insureds/${insured?.id}/documents/${document?.documentRelationshipId}`);
    }
    return;
  };

  const [populate, { data, loading: loadingPopulate, error: errorPopulate }] = useMutation(DocumentMutation, {
    onCompleted: toggleDocumentDownloadModal,
    onError: () => {}
  });

  const [preview, { data: dataPreview, loading: loadingPreview, error: errorPreview, reset }] = useMutation(
    DocumentMutation,
    { onError: () => {} }
  );

  const previewDocument = ({ activeOn: activeOnOverride }) => {
    const variables = {
      documentRelationshipId: document?.documentRelationshipId,
      exposureDocumentId: document?.exposureDocumentId,
      activeOn: (activeOnOverride && moment(activeOnOverride).format(SERVER_SIDE_DATE_FORMAT)) || activeOn
    };
    preview({ variables });
  };

  const populateDocument = ({ activeOn: activeOnOverride, includeStakeholders }) => {
    if (isDocumentRelationship && !!includeStakeholders) {
      startBulkDownloadDocumentsJob({
        activeOn: (activeOnOverride && moment(activeOnOverride).format(SERVER_SIDE_DATE_FORMAT)) || activeOn,
        insuredId: document?.insured?.id,
        documentRelationshipIds: [document?.documentRelationshipId],
        includeStakeholders,
        redirectUrl
      });
      setTimeout(() => !error && toggleDocumentDownloadModal(), 1000);
    } else {
      const variables = {
        documentRelationshipId: document?.documentRelationshipId,
        exposureDocumentId: document?.exposureDocumentId,
        activeOn: (activeOnOverride && moment(activeOnOverride).format(SERVER_SIDE_DATE_FORMAT)) || activeOn
      };
      populate({ variables });
    }
  };

  useEffect(() => {
    if (data?.document) {
      download(data?.document);
    }
  }, [data, download]);

  return (
    <>
      <ActionDropdown>
        <CustomDropdownItem color="info" size="sm" icon={['far', 'search']} onClick={toggleDocumentPreviewModal}>
          Preview
        </CustomDropdownItem>
        <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
          <CustomDropdownItem color="info" size="sm" icon={['far', 'envelope']} onClick={toggleEmailDocumentModal}>
            Email
          </CustomDropdownItem>
        </HideFromRolesAndTypes>
        <CustomDropdownItem
          color="info"
          size="sm"
          icon={['far', 'arrow-to-bottom']}
          onClick={showActiveOn ? toggleDocumentDownloadModal : populateDocument}
        >
          Download
        </CustomDropdownItem>
        <Render if={!!showVersions}>
          <CustomDropdownItem color="info" size="sm" icon={['far', 'file-contract']} onClick={goToVersions}>
            Previous Versions
          </CustomDropdownItem>
        </Render>
      </ActionDropdown>
      {showEmailDocumentModal && (
        <SendDocumentEmailModal
          show={showEmailDocumentModal}
          toggle={toggleEmailDocumentModal}
          document={document}
          activeOn={activeOn}
          showActiveOn={showActiveOn}
          redirectUrl={redirectUrl}
        />
      )}
      {showDocumentPreviewModal && (
        <ShowDocumentPreviewModal
          show={showDocumentPreviewModal}
          toggle={toggleDocumentPreviewModal}
          data={dataPreview}
          previewDocument={previewDocument}
          reset={reset}
          loading={loadingPreview}
          error={errorPreview}
          showActiveOn={showActiveOn}
        />
      )}
      {showDocumentDownloadModal && showActiveOn && (
        <ShowDocumentDownloadModal
          show={showDocumentDownloadModal}
          toggle={toggleDocumentDownloadModal}
          populateDocument={populateDocument}
          loading={loadingPopulate || loading}
          error={errorPopulate || error}
        />
      )}
    </>
  );
}
