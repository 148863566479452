import moment from 'moment';
import { gql, useQuery } from '@apollo/client';

export const LIST_POLICY_DOCUMENT_RELATIONSHIPS = gql`
  query policyDocumentRelationships($policyId: ID!, $policyDocumentIds: [ID]) {
    policyDocumentRelationships: listPolicyDocumentRelationships(
      policyId: $policyId
      policyDocumentIds: $policyDocumentIds
    ) {
      id
      insuredId
      policyDocumentId
      exposureElections {
        id
        startDate
        terminationDate
      }
      insured {
        id
      }
    }
  }
`;

export default function useListPolicyDocumentRelationships({
  policyId,
  policyDocumentIds,
  onCompleted = () => {},
  onError = () => {}
}) {
  const { data, loading, error } = useQuery(LIST_POLICY_DOCUMENT_RELATIONSHIPS, {
    variables: { policyId, policyDocumentIds },
    onCompleted,
    onError
  });

  const policyDocumentRelationships = data?.policyDocumentRelationships;
  const todayFormatted = moment().format(moment.HTML5_FMT.DATE);

  const filteredDocumentRelationships = policyDocumentRelationships?.filter(documentRelationship =>
    documentRelationship?.exposureElections?.filter(exposureElection => exposureElection?.startDate > todayFormatted)
      ?.length === 0
      ? true
      : false
  );

  const allInsuredIds = filteredDocumentRelationships?.map(({ insuredId }) => insuredId);

  const allValidInsuredIds = filteredDocumentRelationships?.map(({ insuredId }) => insuredId);

  const policyDocumentInsuredsMapping = filteredDocumentRelationships?.reduce(
    (acc, { policyDocumentId, insuredId }) => {
      return acc[policyDocumentId]
        ? { ...acc, [policyDocumentId]: [insuredId, ...acc[policyDocumentId]] }
        : { [policyDocumentId]: [insuredId], ...acc };
    },
    {}
  );

  return {
    allInsuredIds: unique(allInsuredIds) || [],
    allValidInsuredIds: unique(allValidInsuredIds) || [],
    allDocumentInsuredsMapping: policyDocumentInsuredsMapping || {},
    allValidDocumentRelationships: filteredDocumentRelationships || [],
    loading,
    error
  };
}

const unique = ids => [...new Set(ids)];
