import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';

import { InsuredStakeholderFormFields } from '.';
import { useInsuredStakeholderFormOptions } from '../hooks';
import { insuredStakeholderInitialValues, insuredStakeholderValidationFields } from '../model/step';
import useUpdateInsuredStakeholder from '../hooks/useUpdateInsuredStakeholder';

export default function UpdateInsuredStakeholder({ stakeholder, isOpen, toggle }) {
  const { update, reset, loading, error } = useUpdateInsuredStakeholder({ stakeholder, onCompleted: toggle });
  const formOptions = useInsuredStakeholderFormOptions();
  const validationSchema = yup.object().shape(insuredStakeholderValidationFields);
  const initialValues = insuredStakeholderInitialValues(stakeholder, formOptions);

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} enableReinitialize onSubmit={update}>
      {formik => (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          onOpened={() => {
            reset();
            formik.handleReset();
          }}
          title="Update Insured Stakeholder"
          loading={loading}
          submitButtonText="Update Insured Stakeholder"
          submitButtonProps={{ loadingText: 'Updating...' }}
          submitButtonDisabled={formik.isSubmitting}
          onSubmit={formik.handleSubmit}
        >
          <GraphQLErrors error={error} />
          <InsuredStakeholderFormFields formOptions={formOptions} />
        </Modal>
      )}
    </Formik>
  );
}
