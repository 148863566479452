import { VIN_VALIDATION } from '../../coverages/components/steps/stepConstants';
import { buildValidationSchema } from '../../vehicle/model';
import * as VehicleStakeholder from '../../vehicleStakeholder/model';
import * as yup from 'yup';

export default function useVehicleFormSteps({
  requiredVehicleFields,
  vehicle,
  formOptions,
  onlyStakeholders,
  includeVinStep = true
}) {
  const vehicleStep = {
    name: 'Vehicle Details',
    stepName: 'VEHICLE_DETAILS',
    validationSchema: buildValidationSchema({ requiredFields: requiredVehicleFields }),
    initialValues: {
      vin: vehicle?.vin || '',
      vehicleModel: vehicle?.vehicleModel || '',
      vehicleMake: vehicle?.vehicleMake || '',
      vehicleYear: vehicle?.vehicleYear || null,
      vehicleWeight: vehicle?.vehicleWeight || null,
      vehicleType: formOptions?.vehicleType?.options?.find(option => option.value === vehicle?.vehicleType?.id) || null,
      fleetNumber: vehicle?.fleetNumber || '',
      unitNumber: vehicle?.unitNumber || '',
      isTractor: vehicle?.isTractor ?? false,
      notes: vehicle?.notes || '',
      serialNumber: vehicle?.serialNumber || '',
      stateOfRegistration:
        formOptions?.allStates?.options?.find(option => option.value === vehicle?.stateOfRegistration) || null,
      value: vehicle?.value || null,
      garagedZip: vehicle?.garagedZip || '',
      garagedState: formOptions?.allStates?.options?.find(option => option.value === vehicle?.garagedState) || null,
      mileage: vehicle?.mileage || '',
      weightClass:
        formOptions?.vehicleWeightClass?.options?.find(option => option.value === vehicle?.weightClass?.id) || null,
      commercialAutoCoverageType:
        formOptions?.coverageType?.options?.find(option => option.value === vehicle?.commercialAutoCoverageType?.id) ||
        null
    }
  };

  const vinValidationStep = {
    name: 'VIN Validation',
    stepName: VIN_VALIDATION,
    validationSchema: yup.object().shape({
      vin: yup
        .string()
        .test('len', 'Must be exactly 17 characters', val => {
          if (val === undefined) {
            return true;
          }
          return val.length === 0 || val.length === 17;
        })
        .required('Required')
    }),
    initialValues: {
      vin: vehicle?.vin || '',
      VinApiFields: {}
    }
  };

  const vehicleStakeholdersStep = VehicleStakeholder.step(vehicle, formOptions);
  const steps = onlyStakeholders
    ? [vehicleStakeholdersStep]
    : includeVinStep
    ? [vinValidationStep, vehicleStep, vehicleStakeholdersStep]
    : [vehicleStep, vehicleStakeholdersStep];

  return {
    formSteps: steps
  };
}
