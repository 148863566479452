import { gql, useSubscription, useQuery } from '@apollo/client';
import { ASSIGNED_ACCOUNTS_FIELDS, POLICY_INVITATIONS_FIELDS } from '../../policyManagers';
import { POLICY_INSURANCE_TYPE_FIELDS } from './useInsuranceType';

export const RETAIL_BROKER_FIELDS = gql`
  fragment RetailBrokerFields on Policy {
    retailBroker {
      id
      name
      companyId
    }
  }
`;

export const INSURANCE_CARRIER_FIELDS = gql`
  fragment InsuranceCarrierFields on Policy {
    insuranceCarrier {
      id
      name
      companyId
    }
  }
`;

export const WHOLESALE_BROKER_FIELDS = gql`
  fragment WholesaleBrokerFields on Policy {
    wholesaleBroker {
      id
      name
      companyId
    }
  }
`; 

export const GET_POLICY_INSURED_COUNT = gql`
  query insuredCount($policyId: ID!) {
    policy(id: $policyId) {
      id
      insuredCount
    }
  }
`;

export const GET_POLICY_PRICING_COUNT = gql`
  query pricingOptionsCount($policyId: ID!) {
    policy(id: $policyId) {
      id
      pricingOptionsCount
    }
  }
`;

export const POLICY_CORE_FIELDS = gql`
  fragment PolicyCoreFields on Policy {
    id
    archiveDate
    name
    isInvoicing
    rolloverPricing
    policyNumber
    pricingRateType
    prorateSetting
    prorateAddons
    externalId
    enableCustomStartDates
    enableCustomTerminationDates
    automateInvoiceGeneration
    invoiceGenerationDay
    invoicingSchedule
    expirationDate
    effectiveDate
    effectiveStatus
    status
    state
    archived
    archiveNotes
    automateInvoiceGeneration
    startDateBackdatingLimit
    startDateBackdatingMinimum
    terminationDateBackdatingLimit
    terminationDateBackdatingMinimum
    invoiceDueDay
    billingRule
    snapshotCustomDay
    apiOnlyExposureUpdates
    customData
    includeStateFees
    onePremiumRequired
    renewedFromPolicyId
    renewedToPolicyId
    backdate
    ratingZoneSource
    netSuiteId
  }
`;

export const GET_POLICY = gql`
  query policy($id: ID!) {
    policy(id: $id) {
      ...PolicyCoreFields
      insuredCount
      pricingOptionsCount
      hasApiFactoredField
      invoiceDueDay
      invoiceCount
      activeExposureCount
      nextInvoiceServiceDates {
        generateAt
      }
      latestInvoice {
        id
      }
      client {
        id
        name
      }
      invoiceTemplate {
        id
        filename
        url
      }
      product {
        id
        automateInvoiceGeneration
        enableCustomStartDates
        enableCustomTerminationDates
        insuranceType {
          id
          name
          type
          requireVehicle
        }
      }
      pricingOptions {
        id
        isPercentageFactor
        factoredField
      }
      ...AssignedAccountsFields
      ...PolicyInvitationsFields
      ...RetailBrokerFields
      ...InsuranceCarrierFields
      ...WholesaleBrokerFields
      ...PolicyInsuranceTypeFields
    }
  }
  ${POLICY_CORE_FIELDS}
  ${ASSIGNED_ACCOUNTS_FIELDS}
  ${POLICY_INVITATIONS_FIELDS}
  ${RETAIL_BROKER_FIELDS}
  ${INSURANCE_CARRIER_FIELDS}
  ${WHOLESALE_BROKER_FIELDS}
  ${POLICY_INSURANCE_TYPE_FIELDS}
`;

const SUBSCRIPTION = gql`
  subscription policyChange($policyId: ID!) {
    change: policyChange(policyId: $policyId) {
      policyId
      archived
    }
  }
`;

const POLICY_FRAGMENT = gql`
  fragment Policy on Policy {
    id
    archived
  }
`;

export default function usePolicy({ id }) {
  const { data, loading, error } = useQuery(GET_POLICY, {
    variables: { id },
    fetchPolicy: 'cache-and-network'
  });

  useSubscription(SUBSCRIPTION, {
    skip: !id,
    variables: { policyId: id },
    onSubscriptionData({ client, subscriptionData: { data } }) {
      const {
        change: { policyId, archived }
      } = data;

      client.writeFragment({
        id: `Policy:${policyId}`,
        fragment: POLICY_FRAGMENT,
        data: { id: policyId, archived }
      });
    }
  });

  return { policy: data?.policy, loading, error };
}
