import { DatePickerField, InputField, ReactCreatableSelectField, Render, SwitchField } from '@oforce/global-components';
import { useState } from 'react';

export default function DocumentEmailFields({ initialEmailOptions, showActiveOn, isDocumentRelationship, formik }) {
  const [emailAddresses, setEmails] = useState(initialEmailOptions);
  const setEmailValues = value => setEmails(values => [...values, { value: value, label: value }]);

  return (
    <>
      {showActiveOn && <DatePickerField fullWidth required name="activeOn" label="Active On" format="MM/DD/YYYY" />}
      <ReactCreatableSelectField
        className="mb-4"
        options={emailAddresses}
        isMulti
        required
        name="emailAddresses"
        label="Email Address(es)"
        onCreateOption={value => setEmailValues(value)}
      />
      <SwitchField
        id="includeStakeholders"
        className="mb-3"
        label="Include Stakeholder Documents"
        name="includeStakeholders"
        size="sm"
        switchLabel={formik?.values?.includeStakeholders ? 'Included' : 'Not Included'}
      />
      <Render if={!isDocumentRelationship}>
        <InputField required label="Email Subject" name="subject" />
        <InputField
          required
          type="textarea"
          label="Email Body"
          name="body"
          messageAfter={'The file included with this email will expire in 7 days.'}
          messageAfterProps={{ icon: ['far', 'info-circle'] }}
        />
      </Render>
    </>
  );
}
