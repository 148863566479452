import { CustomDropdownItem, useToggle } from '@oforce/global-components';
import { Address, Td, Tr } from '../../../components';
import { ActionDropdown, LabelValueCell } from '../../../components/table-2.0';
import { ContactInfoCell } from '../../../components/tables';
import { backendNameToLabel } from '../../../utils/formatting';
import { RemoveInsuredStakeholder, UpdateInsuredStakeholder } from '.';

export default function InsuredStakeholderRow({ stakeholder }) {
  const [editOpen, toggleEdit] = useToggle();
  const [deleteOpen, toggleDelete] = useToggle();

  const { id, relationship, name, phone, email } = stakeholder;

  return (
    <>
      <Tr key={id}>
        <Td>
          <LabelValueCell label={name} value={relationship && backendNameToLabel(relationship)} />
        </Td>
        <Td>
          <div>
            <ContactInfoCell email={email} mobilePhone={phone} />
          </div>
        </Td>
        <Td>
          <Address stakeholder={stakeholder} />
        </Td>
        <Td>
          <ActionDropdown>
            <CustomDropdownItem color="info" icon={['far', 'pen']} onClick={toggleEdit}>
              Edit
            </CustomDropdownItem>
            <CustomDropdownItem color="danger" icon={['far', 'trash-alt']} onClick={toggleDelete}>
              Remove
            </CustomDropdownItem>
          </ActionDropdown>
        </Td>
      </Tr>
      {editOpen && <UpdateInsuredStakeholder stakeholder={stakeholder} isOpen={editOpen} toggle={toggleEdit} />}
      {deleteOpen && <RemoveInsuredStakeholder stakeholder={stakeholder} isOpen={deleteOpen} toggle={toggleDelete} />}
    </>
  );
}
