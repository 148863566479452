import { gql, useMutation } from '@apollo/client';
import { ALL_VEHICLE_STAKEHOLDER_FIELDS } from '../model';
import { GET_VEHICLE_STAKEHOLDERS } from '../../vehicleStakeholders/hooks/useVehicleStakeholders';

const REMOVE_VEHICLE_STAKEHOLDER = gql`
  mutation removeVehicleStakeholder($input: RemoveVehicleStakeholderInput!) {
    removeVehicleStakeholder(input: $input) {
      ...allVehicleStakeholderFields
    }
  }
  ${ALL_VEHICLE_STAKEHOLDER_FIELDS}
`;

export default function useRemoveVehicleStakeholder({ stakeholder, onCompleted = () => {}, onError = () => {} }) {
  const [remove, { loading, error, reset }] = useMutation(REMOVE_VEHICLE_STAKEHOLDER, {
    refetchQueries: [{ query: GET_VEHICLE_STAKEHOLDERS, variables: { id: stakeholder?.vehicleId } }],
    onCompleted,
    onError
  });

  return {
    remove: ({ id, vehicleId, types }) => remove({ variables: { input: { id, vehicleId, types } } }),
    loading,
    error,
    reset
  };
}
