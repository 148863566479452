import { FormSection } from '../../../components';
import { RangePicker } from '@oforce/global-components';
import { Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function InvoicesDateSelection({ formik }) {
  const setDateFilter = dates => {
    if (dates) {
      const [startDate, endDate] = dates;
      let serviceStartDate = null;
      let serviceEndDate = null;
      if (startDate) serviceStartDate = startDate.format('YYYY-MM-DD');
      if (endDate) serviceEndDate = endDate.format('YYYY-MM-DD');
      formik?.setFieldValue('serviceStartDate', serviceStartDate);
      formik?.setFieldValue('serviceEndDate', serviceEndDate);
    } else {
      formik?.setFieldValue('serviceStartDate', null);
      formik?.setFieldValue('serviceEndDate', null);
    }
  };

  return (
    <FormSection title="Select Invoice Date Range">
      <div className="d-flex flex-row flex-wrap">
        <DateRangeFilter onChange={setDateFilter} />
      </div>
      <Alert color="info" className="mb-2">
        {`Leave empty to view all invoices for selection.`}
      </Alert>
    </FormSection>
  );
}

const DateRangeFilter = ({ onChange }) => (
  <div className="d-flex flex-column mr-2 mb-3">
    <RangePicker
      style={{ minHeight: '38px' }}
      format="MM/DD/YYYY"
      onChange={onChange}
      separator={<FontAwesomeIcon icon={['far', 'long-arrow-alt-right']} className="text-gray-500 ml-0" />}
    />
  </div>
);
