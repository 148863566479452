import React from 'react';
import { Bold, ConfirmModal } from '../../../components';
import useRemoveInsuredStakeholder from '../hooks/useRemoveInsuredStakeholder';

export default function RemoveInsuredStakeholder({ stakeholder, isOpen, toggle }) {
  const { remove, reset, loading, error } = useRemoveInsuredStakeholder({ stakeholder, onCompleted: toggle });

  return (
    <ConfirmModal
      isOpen={isOpen}
      toggle={toggle}
      title="Remove member"
      onConfirm={() => remove(stakeholder)}
      confirmButtonText="Remove Insured Stakeholder"
      iconColor="danger"
      confirmButtonProps={{ color: 'danger', loading, loadingText: 'Removing...' }}
      onOpened={reset}
      error={error}
    >
      Are you sure you want to remove <Bold>{stakeholder?.name}</Bold> from this insured? This cannot be undone.
    </ConfirmModal>
  );
}
