import React from 'react';
import { StatusDot, Td, Tr } from '../../../components';
import { DocumentActions } from '.';
import { formatDate } from '../../../utils/formatting';
import { dateHasPassed } from '../../../utils/dates';
import { Tooltip } from 'reactstrap';
import { useToggle } from '@oforce/global-components';

export default function DocumentVersionRow({ documentRelationship, version }) {
  const [showtooltip, toggleTooltip] = useToggle();
  const document = {
    ...documentRelationship,
    documentRelationshipId: documentRelationship.id,
    source: 'DOCUMENT_RELATIONSHIP'
  };
  const dateExpired = version && !!version?.inactiveDate ? dateHasPassed(version?.inactiveDate) : false;

  return (
    <Tr>
      <Td>
        <VersionStatus version={version} dateExpired={dateExpired} />
      </Td>
      <Td>{`Version - ${version?.versionNumber}`}</Td>
      <Td>
        <VersionDates version={version} dateExpired={dateExpired} />
        {!!version?.inactiveDate && !dateExpired && (
          <Tooltip
            isOpen={showtooltip}
            toggle={toggleTooltip}
            target={`active-dates-${version?.id}`}
            placement="bottom"
          >
            {`Pending expiration on ${formatDate(version?.inactiveDate)}`}
          </Tooltip>
        )}
      </Td>

      <Td>
        <VersionInactiveReason version={version} dateExpired={dateExpired} />
      </Td>
      <Td>
        <DocumentActions
          redirectUrl={`/insureds/${document?.insured?.id}/documents/${documentRelationship?.id}`}
          document={document}
          activeOn={version.activeDate}
          showVersions={false}
          showActiveOn={false}
        />
      </Td>
    </Tr>
  );
}

const VersionInactiveReason = ({ version, dateExpired }) => {
  const inactiveReason = version?.inactiveReason?.split('_').join(' ');

  if (dateExpired && !inactiveReason) return 'Data Change';
  if (dateExpired && !!inactiveReason) return inactiveReason;
  return <EmptyCell />;
};

const VersionStatus = ({ dateExpired }) => {
  const color = dateExpired ? 'red' : 'green';
  return <StatusDot color={color} />;
};

const VersionDates = ({ version, dateExpired }) => {
  const dates = getActiveDates({ version, dateExpired });

  if (!!dates) return <span id={`active-dates-${version?.id}`}>{dates}</span>;
  return <EmptyCell text="None" />;
};

const getActiveDates = ({ version, dateExpired }) => {
  if (version?.activeDate && dateExpired && version?.inactiveDate)
    return `${formatDate(version?.activeDate)} - ${formatDate(version?.inactiveDate)}`;
  if (version?.activeDate) return `${formatDate(version?.activeDate)} - Current`;
  return null;
};

const EmptyCell = ({ text = 'None' }) => <div className="font-weight-300 fs-xs font-italic text-muted">{text}</div>;
