import moment from 'moment';
import { gql, useMutation } from '@apollo/client';
import * as Insured from './../model';
import { ALL_INSURED_STAKEHOLDER_FIELDS } from '../../insuredStakeholder/model/fragments';
import { prepareValuesForSubmit } from '../../coverages/components/steps/hooks/submitPrepare';

const UPDATE_INSURED = gql`
  mutation updateInsured($input: UpdateInsuredInput!) {
    updateInsured(input: $input) {
      id
      firstName
      middleName
      lastName
      businessName
      businessName2
      address
      ssnLastFour
      city
      state
      country
      dateOfBirth
      zip
      email
      mobilePhone
      insertedAt
      updatedAt
      driversLicenseNumber
      driversLicenseState
      dispatchLocation
      elections {
        id
        pricingOption {
          id
          label
        }
      }
      stakeholders {
        ...allInsuredStakeholderFields
      }
    }
  }
  ${ALL_INSURED_STAKEHOLDER_FIELDS}
`;

export default function useUpdateInsuredForm({ insured, formOptions, onCompleted, onError = () => {} }) {
  const [update, { loading, error, reset }] = useMutation(UPDATE_INSURED, {
    onCompleted,
    onError
  });

  const initialValues = Insured.initialValues({ insured, formOptions });

  const handle_ssn = (attrs, ssn) => {
    if (ssn && ssn?.startsWith('X')) {
      return attrs;
    } else if (ssn) {
      return { ...attrs, ssn: ssn?.split('-').join('') };
    }

    return attrs;
  };

  const handleSubmit = ({ dateOfBirth, ssn, state, country, driversLicenseState, insuredStakeholders, ...values }) => {
    const attrs = {
      id: values?.id,
      dateOfBirth: dateOfBirth ? moment(dateOfBirth, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
      state: state?.value ? state?.value : null,
      driversLicenseState: driversLicenseState?.value ? driversLicenseState?.value : null,
      country: country?.value ? country?.value : '',
      stakeholders: insuredStakeholders?.map(x => prepareValuesForSubmit(x)),
      ...values
    };

    const input = handle_ssn(attrs, ssn);

    update({ variables: { input } });
  };

  return {
    initialValues,
    handleSubmit,
    loading,
    error,
    reset
  };
}
