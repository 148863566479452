import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

import { BoxItem, BoxItemTitle } from '../../../components';
import { backendNameToLabel } from '../../../utils/formatting';

export default function StakeholderBoxItem({ setUpdatingStakeholder, removeStakeholder, stakeholder }) {
  const types = stakeholder?.types?.map(x => (x?.value ? x?.value : x))?.join(', ');
  return (
    <>
      <BoxItem className={`border rounded border-gray-400 d-flex justify-content-between m-1`}>
        <BoxItemTitle title={stakeholder?.name} subtitle={backendNameToLabel(types)} />
        <div>
          <Button color="link" type="button">
            <FontAwesomeIcon
              onClick={() => setUpdatingStakeholder(stakeholder)}
              type="button"
              icon={['far', 'pencil']}
              className="ml-2"
            />
          </Button>
          <Button color="link" type="button">
            <FontAwesomeIcon
              onClick={() => removeStakeholder(stakeholder)}
              icon={['far', 'trash-alt']}
              className="ml-2"
            />
          </Button>
        </div>
      </BoxItem>
    </>
  );
}
