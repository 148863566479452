import { gql, useMutation } from '@apollo/client';
import { ALL_INSURED_STAKEHOLDER_FIELDS } from '../model';
import { prepareValuesForSubmit } from '../../coverages/components/steps/hooks/submitPrepare';
import { GET_INSURED_STAKEHOLDERS } from './useInsuredStakeholders';

const UPDATE_INSURED_STAKEHOLDER = gql`
  mutation updateInsuredStakeholder($input: UpdateInsuredStakeholderInput!) {
    updateInsuredStakeholder(input: $input) {
      ...allInsuredStakeholderFields
    }
  }
  ${ALL_INSURED_STAKEHOLDER_FIELDS}
`;

export default function useUpdateInsuredStakeholder({ stakeholder, onCompleted = () => {}, onError = () => {} }) {
  const [update, { loading, error, reset }] = useMutation(UPDATE_INSURED_STAKEHOLDER, {
    refetchQueries: [{ query: GET_INSURED_STAKEHOLDERS, variables: { id: stakeholder?.insuredId } }],
    onCompleted,
    onError
  });

  const submit = values => {
    const { id, ...rest } = values;
    const attrs = prepareValuesForSubmit(rest);
    const input = { ...attrs, insuredId: stakeholder?.insuredId, stakeholderId: id };
    update({ variables: { input } });
  };

  return {
    update: submit,
    loading,
    error,
    reset
  };
}
