import { gql, useMutation } from '@apollo/client';

import { ALL_VEHICLE_STAKEHOLDER_FIELDS } from '../model';
import { GET_VEHICLE_STAKEHOLDERS } from '../../vehicleStakeholders/hooks/useVehicleStakeholders';
import { prepareValuesForSubmit } from '../../coverages/components/steps/hooks/submitPrepare';

const ADD_VEHICLE_STAKEHOLDER = gql`
  mutation addVehicleStakeholder($input: AddVehicleStakeholderInput!) {
    addVehicleStakeholder(input: $input) {
      ...allVehicleStakeholderFields
    }
  }
  ${ALL_VEHICLE_STAKEHOLDER_FIELDS}
`;

export default function useAddVehicleStakeholder({ vehicle, onCompleted = () => {}, onError = () => {} }) {
  const [add, { loading, error, reset }] = useMutation(ADD_VEHICLE_STAKEHOLDER, {
    refetchQueries: [{ query: GET_VEHICLE_STAKEHOLDERS, variables: { id: vehicle?.id } }],
    onCompleted,
    onError
  });

  const submit = values => {
    const attrs = prepareValuesForSubmit(values);
    const input = { ...attrs, vehicleId: vehicle?.id };
    add({ variables: { input } });
  };

  return {
    add: submit,
    loading,
    error,
    reset
  };
}
