import { gql, useQuery } from '@apollo/client';
import { ALL_VEHICLE_STAKEHOLDER_FIELDS } from '../../vehicleStakeholder/model';

export const GET_VEHICLE_STAKEHOLDERS = gql`
  query getVehicle($id: ID!) {
    vehicle: getVehicle(id: $id) {
      id
      stakeholders {
        ...allVehicleStakeholderFields
      }
    }
  }
  ${ALL_VEHICLE_STAKEHOLDER_FIELDS}
`;

export default function useVehicleStakeholders({ vehicleId }) {
  const { data, loading, error } = useQuery(GET_VEHICLE_STAKEHOLDERS, {
    variables: { id: vehicleId },
    skip: !vehicleId,
    fetchPolicy: !vehicleId ? 'cache-only' : 'cache-first'
  });

  const stakeholders = data?.vehicle?.stakeholders || [];

  return { stakeholders, loading, error };
}
