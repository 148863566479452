import React from 'react';
import { Row } from 'reactstrap';
import { useVehicle } from '../../vehicle/hooks';
import { VehicleDetails, VehicleCoverages } from './';
import { Button, Loader, useToggle } from '@oforce/global-components';
import { GraphQLErrors, TabPage } from '../../../components';
import { VehicleStakeholders } from '../../vehicleStakeholders';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useActiveAccount } from '../../../shared/activeAccount';
import { HideFromRolesAndTypes } from '../../../shared/activeAccount/components';
import AddVehicleStakeholder from '../../vehicleStakeholder/components/AddVehicleStakeholder';

export default function VehicleOverview() {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { vehicleId } = useParams();
  const [showAddStakeholder, toggleAddStakeholder] = useToggle();

  const { vehicle, loading, error } = useVehicle({ vehicleId });

  const {
    ROLES: { OBSERVER },
    TYPES: { CLAIMS_ADJUSTER }
  } = useActiveAccount();

  if (loading) return <Loader />;
  if (error) return <GraphQLErrors error={error} />;

  return (
    <>
      <Row>
        <TabPage
          title={'Details'}
          onBack={() => history.goBack()}
          className="pr-4 flex-grow-1 "
          marginBottom="mb-0"
          contentClassName="mt-1"
          titleClassName="mb-1"
          buttons={
            <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
              <Button color="info" icon={['far', 'pen']} onClick={() => history.push(`${url}/edit`)}>
                Edit
              </Button>
            </HideFromRolesAndTypes>
          }
        >
          <VehicleDetails vehicle={vehicle} />
        </TabPage>
        <TabPage
          title="Stakeholders"
          marginBottom="mb-0"
          contentClassName="mt-1"
          titleClassName="mb-1"
          className="flex-grow-1 mt-0"
          buttons={
            <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
              <Button className="mb-1" color="info" icon={['far', 'plus']} onClick={toggleAddStakeholder}>
                Add Stakeholder
              </Button>
            </HideFromRolesAndTypes>
          }
        >
          <VehicleStakeholders vehicle={vehicle} loading={loading} error={error} />
        </TabPage>
      </Row>
      <Row>
        <VehicleCoverages vehicleId={vehicleId} />
      </Row>
      {showAddStakeholder && (
        <AddVehicleStakeholder vehicle={vehicle} isOpen={showAddStakeholder} toggle={toggleAddStakeholder} />
      )}
    </>
  );
}
