import React from 'react';
import { useVehicleStakeholders } from './hooks';
import { VehicleStakeholderRow } from './components';
import { BoxContainer, Table, TrGraphQLErrors } from '../../components';
import { RenderData, TrLoader, TrPlaceholder } from '@oforce/global-components';

export default function VehicleStakeholders({ vehicle, loading, error }) {
  const { stakeholders } = useVehicleStakeholders({ vehicleId: vehicle?.id });

  return (
    <BoxContainer className="px-3 py-2 mb-5">
      <Table responsive>
        <thead>
          <tr>
            <th>Stakeholder</th>
            <th>Contact</th>
            <th>Address</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <RenderData
            data={stakeholders}
            loading={loading}
            error={error}
            loadingComponent={<TrLoader colSpan={5} />}
            errorComponent={<TrGraphQLErrors colSpan={5} error={error} />}
            placeholderComponent={<TrPlaceholder colSpan={5}>None</TrPlaceholder>}
          >
            {data =>
              data.map(stakeholder => (
                <VehicleStakeholderRow key={`vehicle-stakeholder-row${stakeholder?.id}`} stakeholder={stakeholder} />
              ))
            }
          </RenderData>
        </tbody>
      </Table>
    </BoxContainer>
  );
}
