import React, { useState } from 'react';
import { FormSection } from '../../../../components';
import { StakeholderBoxItem } from '../../../stakeholder/components';
import { Button, Render } from '@oforce/global-components';
import { uuid } from '../../../../utils/regex';
import VehicleStakeholderForm from '../../../vehicleStakeholder/components/VehicleStakeholderForm';
import { Row } from 'reactstrap';

export default function VehicleStakeholdersStep({ formik, formOptions, vehicle }) {
  formik.validateOnChange = true;
  const [stakeholders, setStakeholders] = useState(formik?.values?.stakeholders || []);
  const [updatingStakeholder, setUpdatingStakeholder] = useState(false);

  const removeStakeholder = ({ id }) => {
    const newSakeholders = stakeholders.filter(x => x.id !== id);
    formik.setFieldValue('stakeholders', newSakeholders);
    setStakeholders(newSakeholders);
  };

  const remove_new_id = stakeholder => {
    if (stakeholder?.id?.includes('new-')) return { ...stakeholder, id: null };
    return stakeholder;
  };

  const addStakeholder = stakeholder => {
    const newSakeholders = stakeholders.filter(x => x.id !== stakeholder?.id);
    const newStakeholder = { ...stakeholder, vehicleId: vehicle?.id };

    formik.setFieldValue(
      'stakeholders',
      [...newSakeholders, newStakeholder].map(x => remove_new_id(x))
    );

    setStakeholders([...newSakeholders, newStakeholder]);
  };

  return (
    <FormSection title="Vehicle Stakeholders">
      <Render if={!updatingStakeholder}>
        <Render if={stakeholders.length > 0}>
          <Row className="mr-3 mb-1 justify-content-end">
            <Button
              onClick={() => setUpdatingStakeholder({ id: `new-${uuid()}` })}
              color="link"
              type="button"
              icon={['far', 'plus']}
            >
              Add Stakeholder
            </Button>
          </Row>
        </Render>
        <Render if={stakeholders.length === 0}>
          <div className="d-flex justify-content-center">
            <Button
              onClick={() => setUpdatingStakeholder({ id: `new-${uuid()}` })}
              color="success"
              type="button"
              icon={['far', 'plus']}
            >
              Add Stakeholder
            </Button>
          </div>
        </Render>

        <div className="d-flex flex-column justify-content-between">
          {stakeholders?.map(stakeholder => {
            return (
              <StakeholderBoxItem
                key={stakeholder?.id}
                stakeholder={stakeholder}
                setUpdatingStakeholder={setUpdatingStakeholder}
                removeStakeholder={removeStakeholder}
                formik={formik}
              />
            );
          })}
        </div>
      </Render>
      <Render if={!!updatingStakeholder}>
        <VehicleStakeholderForm
          formOptions={formOptions}
          stakeholder={updatingStakeholder}
          onSubmit={values => {
            addStakeholder(values);
            setUpdatingStakeholder(false);
          }}
          onCancel={() => setUpdatingStakeholder(false)}
        />
      </Render>
    </FormSection>
  );
}
