import * as yup from 'yup';

export const vehicleStakeholderValidationFields = {
  id: yup.string().nullable(),
  vehicleId: yup.string().nullable(),
  name: yup.string().required('Required'),
  address1: yup.string().nullable(),
  address2: yup.string().nullable(),
  city: yup.string().nullable(),
  state: yup.string().nullable(),
  zip: yup.string().nullable(),
  email: yup.string().nullable(),
  phone: yup.string().nullable(),
  types: yup.array().of(yup.object()).required('Required')
};

const findOption = (type, options) => {
  if (!!type?.value) return type;
  return options?.find(x => x.value === type);
};

export const vehicleStakeholderInitialValues = (stakeholder, formOptions) => ({
  id: stakeholder?.id,
  vehicleId: stakeholder?.vehicleId,
  name: stakeholder?.name,
  address1: stakeholder?.address1,
  address2: stakeholder?.address2,
  city: stakeholder?.city,
  state: stakeholder?.state ? findOption(stakeholder?.state, formOptions?.allStates?.options) : null,
  zip: stakeholder?.zip,
  email: stakeholder?.email,
  phone: stakeholder?.phone,
  types: stakeholder?.types?.map(type => findOption(type, formOptions?.vehicleStakeholderTypes?.options)) || []
});

export default function step(vehicle, formOptions) {
  const validationSchema = yup.object({
    stakeholders: yup.array().of(yup.object().shape(vehicleStakeholderValidationFields))
  });

  const stakeholders = vehicle?.stakeholders?.map(x => vehicleStakeholderInitialValues(x, formOptions));

  return {
    name: 'Vehicle Stakeholders',
    validationSchema: validationSchema,
    initialValues: { stakeholders }
  };
}
