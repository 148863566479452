import React from 'react';
import { Row, Col } from 'reactstrap';
import { createFilter } from 'react-select';
import { InputField, ReactSelectField } from '@oforce/global-components';

export default function VehicleStakeholderFormFields({ formOptions }) {
  const filterConfig = { matchFrom: 'start' };

  return (
    <>
      <InputField name={'name'} label="Name" required />
      <InputField name={'relationship'} label="Relationship" />
      <InputField name={'email'} label="Email" type="email" />
      <InputField name={'phone'} label="Phone" type="tel" />
      <InputField name={'address1'} label="Address 1" />
      <InputField name={'address2'} label="Address 2" />
      <Row form>
        <Col>
          <InputField name={'city'} label="City" />
        </Col>
        <Col>
          <ReactSelectField
            name={'state'}
            label="State"
            options={formOptions?.allStates?.options}
            filterOption={createFilter(filterConfig)}
            isClearable
          />
        </Col>
        <Col>
          <InputField name={'zip'} label="Zip" />
        </Col>
      </Row>
    </>
  );
}
