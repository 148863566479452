import { gql, useQuery } from '@apollo/client';
import { VEHICLE_CORE_FIELDS } from '../../insuredVehicles/constants';
import { ALL_VEHICLE_STAKEHOLDER_FIELDS } from '../../vehicleStakeholder/model';
import { ALL_INSURED_STAKEHOLDER_FIELDS } from '../../insuredStakeholder/model/fragments';

export const GET_INSURED = gql`
  query insured($id: ID!) {
    insured(id: $id) {
      id
      firstName
      middleName
      lastName
      country
      dateOfBirth
      age
      insertedAt
      updatedAt
      mobilePhone
      email
      businessName
      businessName2
      address
      state
      city
      zip
      ssnLastFour
      encryptedSsn
      requiredFields
      driversLicenseState
      driversLicenseNumber
      dispatchLocation
      stakeholders {
        ...allInsuredStakeholderFields
      }
      vehicles {
        electionCount
        ...VehicleCoreFields
      }
      elections {
        id
        startDate
        premiumTotal
        total
        pricingRateType
        status
        contractType
        policyId
        masterElection {
          id
          insured {
            id
            firstName
            middleName
            lastName
            businessName
          }
        }
        pricingOption {
          id
          rateMode
          factoredField
        }
        compliance {
          inCompliance
        }
        policy {
          id
          archived
          expirationDate
          client {
            id
            name
          }
          insuranceType {
            id
            type
            name
            requireVehicle
          }
        }
        addOns {
          id
          name
          label
        }
        insured {
          email
        }
        vehicle {
          electionCount
          ...VehicleCoreFields
          stakeholders {
            ...allVehicleStakeholderFields
          }
        }
      }
    }
  }
  ${VEHICLE_CORE_FIELDS}
  ${ALL_VEHICLE_STAKEHOLDER_FIELDS}
  ${ALL_INSURED_STAKEHOLDER_FIELDS}
`;

export default function useInsured({ id }) {
  const { data, loading, error } = useQuery(GET_INSURED, {
    skip: !id,
    fetchPolicy: !id ? 'cache-only' : 'cache-first',
    variables: { id }
  });

  return {
    insured: data?.insured,
    loading,
    error
  };
}
