import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';

import { InsuredStakeholderFormFields } from '.';
import { useInsuredStakeholderFormOptions } from '../hooks';
import { insuredStakeholderInitialValues, insuredStakeholderValidationFields } from '../model/step';
import useAddInsuredStakeholder from '../hooks/useAddInsuredStakeholder';

export default function AddInsuredStakeholder({ insured, isOpen, toggle }) {
  const { add, reset, loading, error } = useAddInsuredStakeholder({ insured, onCompleted: toggle });
  const formOptions = useInsuredStakeholderFormOptions();
  const validationSchema = yup.object().shape(insuredStakeholderValidationFields);
  const initialValues = insuredStakeholderInitialValues({ types: ['BENEFICIARY'] }, formOptions);

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} enableReinitialize onSubmit={add}>
      {formik => (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          onOpened={() => {
            reset();
            formik.handleReset();
          }}
          title="Add Insured Stakeholder"
          loading={loading}
          submitButtonText="Add Insured Stakeholder"
          submitButtonProps={{ loadingText: 'Adding...' }}
          submitButtonDisabled={formik.isSubmitting}
          onSubmit={formik.handleSubmit}
        >
          <GraphQLErrors error={error} />
          <InsuredStakeholderFormFields formOptions={formOptions} />
        </Modal>
      )}
    </Formik>
  );
}
