import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { backendNameToLabel } from '../../../utils/formatting';

const GET_VEHICLE_STAKEHOLDER_TYPES = gql`
  query vehicleStakeholderTypes {
    vehicleStakeholderTypes
  }
`;

export default function useVehicleStakeholderTypeOptions() {
  const { loading, error, data } = useQuery(GET_VEHICLE_STAKEHOLDER_TYPES);

  return {
    loading,
    error,
    types: data?.vehicleStakeholderTypes,
    options: data?.vehicleStakeholderTypes?.map(type => ({
      label: backendNameToLabel(type),
      value: type
    }))
  };
}
