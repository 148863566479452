import { gql, useMutation } from '@apollo/client';

import { GET_POLICY_INSURED_COUNT } from '../../../../policy/hooks/usePolicy';
import { POLICY_INSURED_FIELDS } from '../../../../policyInsureds/fragments';
import { INSURED_ELECTIONS_FIELDS } from '../../../../insuredElections';
import { prepareInsuredAttrs, prepareElectionAttrs, prepareVehicleAttrs } from './submitPrepare';
import { ALL_INSURED_STAKEHOLDER_FIELDS } from '../../../../insuredStakeholder/model/fragments';

const CREATE_INSURED = gql`
  mutation create($input: CreateCompleteInsuredInput!) {
    createCompleteInsured(input: $input) {
      ...PolicyInsuredFields
      elections {
        ...InsuredElectionsFields
      }
      stakeholders {
        ...allInsuredStakeholderFields
      }
    }
  }
  ${POLICY_INSURED_FIELDS}
  ${INSURED_ELECTIONS_FIELDS}
  ${ALL_INSURED_STAKEHOLDER_FIELDS}
`;

export default function useInsuredCreateFormSubmit({
  vehicle,
  policy,
  insured,
  onCompleted = () => {},
  onError = () => {}
}) {
  const [createInsured, { loading, error }] = useMutation(CREATE_INSURED, {
    onCompleted,
    onError,
    refetchQueries: [
      {
        query: GET_POLICY_INSURED_COUNT,
        variables: { policyId: policy?.id }
      }
    ]
  });

  const formSubmit = async (values, stepsToRender, coverages) => {
    await createInsured({
      variables: {
        input: {
          insuredInfo: prepareInsuredAttrs(values, stepsToRender, insured),
          elections: prepareElectionAttrs(coverages),
          ...prepareVehicleAttrs(values, stepsToRender, vehicle)
        }
      }
    });
  };

  return { formSubmit, loading, error };
}
