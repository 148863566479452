import { useEffect, useMemo } from 'react';
import { gql, useLazyQuery, useSubscription } from '@apollo/client';
import { DOCUMENT_TYPE } from '..';

const ON_NEW_PRODUCT_DOCUMENT = gql`
  subscription onNewProductDocument($productId: ID!) {
    productDocumentUploaded(productId: $productId) {
      id
    }
  }
`;

export const GET_POLICY_DOCUMENTS = gql`
  query policyDocuments($id: ID!) {
    policy(id: $id) {
      id
      product {
        id
      }
      documents {
        id
        appliesToAllPricingOptions
        filename
        url
        pricingOptionIds
        excludeFromAllPolicyPricingOptions
        type
        name
        source
        type
        stateSource
        appliesToStates
        excludeFromStates
        populateOnPending
        effectiveDate
        expirationDate
      }
      clientDocuments {
        id
        effectiveDate
        expirationDate
        document {
          id
          filename
          url
          type
          name
        }
      }
    }
  }
`;

export default function usePolicyDocuments({ policyId, onCompleted = () => {}, onError = () => {} }) {
  const [getPolicyDocuments, { loading, error, data }] = useLazyQuery(GET_POLICY_DOCUMENTS, {
    fetchPolicy: 'cache-and-network',
    variables: { id: policyId },
    onCompleted,
    onError
  });

  useEffect(() => {
    getPolicyDocuments();
  }, [getPolicyDocuments]);

  const productId = data?.policy?.product?.id;

  useSubscription(ON_NEW_PRODUCT_DOCUMENT, {
    variables: { productId },
    skip: productId === null,
    onSubscriptionData(subData) {
      getPolicyDocuments();
    }
  });

  let clientDocuments = data?.policy?.clientDocuments?.map(({ document, id, effectiveDate, expirationDate }) => ({
    ...document,
    id,
    effectiveDate,
    expirationDate,
    appliesToAllPricingOptions: true,
    source: 'CLIENT'
  }));

  let documents = data?.policy?.documents?.concat(clientDocuments);

  const hasCertificatesOfInsurance = useMemo(() => {
    var cert = data?.policy?.documents?.find(x => x.type === DOCUMENT_TYPE.CERTIFICATE_OF_INSURANCE);
    return !!cert;
  }, [data]);

  return {
    loading,
    error,
    hasCertificatesOfInsurance,
    documents: documents || []
  };
}
