import { gql } from '@apollo/client';

export const ALL_INSURED_STAKEHOLDER_FIELDS = gql`
  fragment allInsuredStakeholderFields on InsuredStakeholder {
    id
    name
    types
    relationship
    insuredId
    address1
    address2
    city
    email
    phone
    state
    zip
  }
`;
