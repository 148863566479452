import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import VehicleStakeholderFormFields from './VehicleStakeholderFormFields';
import { Button } from '@oforce/global-components';
import { vehicleStakeholderInitialValues, vehicleStakeholderValidationFields } from '../model/step';

export default function VehicleStakeholderForm({ formOptions, stakeholder, onSubmit, onCancel }) {
  const validationSchema = yup.object().shape(vehicleStakeholderValidationFields);
  const initialValues = vehicleStakeholderInitialValues(stakeholder, formOptions);

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} enableReinitialize onSubmit={onSubmit}>
      {formik => (
        <>
          <VehicleStakeholderFormFields formOptions={formOptions} />
          <div className="d-flex mt-1 justify-content-end">
            <Button type="button" onClick={formik.handleSubmit} className="mr-2" color="success">
              Save
            </Button>
            <Button
              onClick={() => {
                formik.resetForm();
                onCancel();
              }}
              color="danger"
            >
              Cancel
            </Button>
          </div>
        </>
      )}
    </Formik>
  );
}
