import { useCanadianProvinces, useFormOptions, useUSStates } from '../../../hooks';
import useVehicleStakeholderTypeOptions from './useVehicleStakeholderTypeOptions';

export default function useVehicleStakeholderFormOptions() {
  const { options: stateOptions } = useUSStates();
  const { options: canadianProvincesOptions } = useCanadianProvinces();
  const { options: vehicleStakeholderTypeOptions, loading } = useVehicleStakeholderTypeOptions();

  return useFormOptions({
    options: [
      { name: 'vehicleStakeholderTypes', options: vehicleStakeholderTypeOptions, loading },
      { name: 'allStates', options: [...stateOptions, ...canadianProvincesOptions] }
    ]
  });
}
