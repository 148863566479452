import { gql, useMutation } from '@apollo/client';
import { ALL_INSURED_STAKEHOLDER_FIELDS } from '../model';
import { GET_INSURED_STAKEHOLDERS } from './useInsuredStakeholders';

const REMOVE_INSURED_STAKEHOLDER = gql`
  mutation removeInsuredStakeholder($input: RemoveInsuredStakeholderInput!) {
    removeInsuredStakeholder(input: $input) {
      ...allInsuredStakeholderFields
    }
  }
  ${ALL_INSURED_STAKEHOLDER_FIELDS}
`;

export default function useRemoveInsuredStakeholder({ stakeholder, onCompleted = () => {}, onError = () => {} }) {
  const [remove, { loading, error, reset }] = useMutation(REMOVE_INSURED_STAKEHOLDER, {
    refetchQueries: [{ query: GET_INSURED_STAKEHOLDERS, variables: { id: stakeholder?.insuredId } }],
    onCompleted,
    onError
  });

  return {
    remove: ({ id, insuredId }) => remove({ variables: { input: { stakeholderId: id, insuredId } } }),
    loading,
    error,
    reset
  };
}
