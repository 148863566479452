import React, { useState } from 'react';
import { Steps } from 'react-step-builder';
import { MultiStepForm } from '../../../components';
import { useHistory, useParams } from 'react-router-dom';
import { useVehicle, useVehicleFormSubmit, useVehicleFormOptions, useVehicleFormSteps } from '../hooks';
import { VehicleStakeholdersStep, VehicleStep, VinValidationStep } from '../../coverages/components/steps';
import { VIN_VALIDATION } from '../../coverages/components/steps/stepConstants';

export default function VehicleUpdate({ onCompleted, onCancel }) {
  const { vehicleId } = useParams();
  const history = useHistory();

  const [disabledVehicleFields, setDisabledVehicleFields] = useState({});

  const { vehicle } = useVehicle({ vehicleId });
  const requiredVehicleFields = vehicle?.requiredFields.reduce((acc, field) => {
    return { ...acc, [field]: true };
  }, {});

  const { formOptions } = useVehicleFormOptions();
  const { formSteps } = useVehicleFormSteps({ requiredVehicleFields, vehicle, formOptions });

  const { formSubmit, loading, error } = useVehicleFormSubmit({
    vehicle,
    onCompleted: onCompleted ? onCompleted : () => history.goBack()
  });

  return (
    <MultiStepForm
      formOptions={formOptions}
      formSteps={formSteps}
      formSubmit={formSubmit}
      formProps={{
        disabledVehicleFields,
        setDisabledVehicleFields
      }}
      title={'Edit Vehicle'}
      onCancel={() => (onCancel ? onCancel(vehicle) : history.goBack())}
      submitButtonText={'Edit'}
      submitButtonProps={{ loading, loadingText: 'Saving...' }}
      showButtonsOnBottom
      error={error}
    >
      {formProps => (
        <Steps>
          <VinValidationStep key={VIN_VALIDATION} {...formProps} />
          <VehicleStep {...formProps} requiredFields={requiredVehicleFields} disableVinUpdate={true} />
          <VehicleStakeholdersStep vehicle={vehicle} {...formProps} />
        </Steps>
      )}
    </MultiStepForm>
  );
}
