import React, { useEffect, useState } from 'react';
import { Steps } from 'react-step-builder';
import { MultiStepForm } from '../../../components';
import { useHistory } from 'react-router-dom';
import { useInsuredFormOptions, useInsuredFormSteps, useUpdateInsuredForm } from '../hooks';
import { InsuredStakeholdersStep } from '../../coverages/components/steps';
import { Loader, Render } from '@oforce/global-components';
import InsuredFields from './InsuredFields';

export default function InsuredStakeholdersUpdate({ insured, onCompleted, onCancel }) {
  const history = useHistory();

  const requiredFields = insured?.requiredFields.reduce((acc, field) => {
    return { ...acc, [field]: true };
  }, {});

  const formOptions = useInsuredFormOptions();
  const { formSteps } = useInsuredFormSteps({ requiredFields, insured, formOptions });

  const { handleSubmit, loading, error } = useUpdateInsuredForm({
    formOptions,
    insured,
    onCompleted
  });

  const [loadingSchemaValidation, setLoadingSchemaValidation] = useState(true);
  const [hasInsuredErrors, setHasInsuredErrors] = useState(false);

  const coreInsured = formSteps?.find(formStep => formStep?.stepName === 'INSURED_DETAILS');

  useEffect(() => {
    coreInsured?.validationSchema
      .validate(coreInsured?.initialValues)
      .then(() => {
        setHasInsuredErrors(false);
        setLoadingSchemaValidation(false);
      })
      .catch(() => {
        setHasInsuredErrors(true);
        setLoadingSchemaValidation(false);
      });
  }, [hasInsuredErrors, coreInsured]);

  if (loadingSchemaValidation) return <Loader />;

  return (
    <MultiStepForm
      formOptions={formOptions}
      formSteps={formSteps}
      formSubmit={handleSubmit}
      formProps={{
        isCreateMode: false
      }}
      title={hasInsuredErrors ? 'Edit Insured' : 'Edit Beneficiary'}
      onCancel={() => (onCancel ? onCancel(insured) : history.goBack())}
      submitButtonText={'Save'}
      submitButtonProps={{ loading, loadingText: 'Saving...' }}
      showButtonsOnBottom
      error={error}
    >
      {formProps => (
        <>
          <Render if={hasInsuredErrors}>
            <Steps>
              <InsuredFields {...formProps} requiredFields={requiredFields} />
              <InsuredStakeholdersStep {...formProps} insured={insured} />
            </Steps>
          </Render>
          <Render if={!hasInsuredErrors}>
            <Steps>
              <InsuredStakeholdersStep {...formProps} insured={insured} />
            </Steps>
          </Render>
        </>
      )}
    </MultiStepForm>
  );
}
