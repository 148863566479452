import React from 'react';
import { FormSection } from '../../../components';
import { SwitchField } from '@oforce/global-components';

export default function BulkDownloadDocumentsIncludeStakeholders({ formik }) {
  return (
    <FormSection title="Include Stakeholder Documents">
      <SwitchField
        id="includeStakeholders"
        className="mb-3"
        name="includeStakeholders"
        optional
        size="sm"
        switchLabel={formik?.values?.includeStakeholders ? 'Include' : 'Exclude'}
      />
    </FormSection>
  );
}
