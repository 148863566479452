export default function Address({ insured, stakeholder, className }) {
  let addressData = {};
  if (insured) addressData = normalizeInsuredAddress(insured);
  else if (stakeholder) addressData = normalizeStakeholderAddress(stakeholder);
  return formatAddress({ ...addressData, className });
}

function normalizeStakeholderAddress(stakeholder) {
  let addressParts = [];
  const address1 = stakeholder?.address1 || '';
  const address2 = stakeholder?.address2 ? ' ' + stakeholder?.address2 : '';
  const address = address1 + address2;

  !!stakeholder?.city && addressParts.push(stakeholder?.city);
  !!stakeholder?.state && addressParts.push(stakeholder?.state);
  !!stakeholder?.zip && addressParts.push(stakeholder?.zip);

  return { address, addressParts };
}

function normalizeInsuredAddress(insured) {
  const address = insured?.address || '';
  let addressParts = [];

  !!insured?.city && addressParts.push(insured?.city);
  !!insured?.state && addressParts.push(insured?.state);
  !!insured?.zip && addressParts.push(insured?.zip);
  return { address, addressParts };
}

function formatAddress({ address, addressParts, className }) {
  const addressCityStateZip = `${addressParts?.join(', ')}`;
  if (!!addressCityStateZip || !!address) {
    return (
      <div className={className}>
        {address}
        <br />
        {addressCityStateZip}
      </div>
    );
  }
  return <span className="text-gray-500">None</span>;
}
