import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';

import { VehicleStakeholderFormFields } from '.';
import { useVehicleStakeholderFormOptions } from '../hooks';
import { vehicleStakeholderInitialValues, vehicleStakeholderValidationFields } from '../model/step';
import useUpdateVehicleStakeholder from '../hooks/useUpdateVehicleStakeholder';

export default function UpdateVehicleStakeholder({ stakeholder, isOpen, toggle }) {
  const { update, reset, loading, error } = useUpdateVehicleStakeholder({ stakeholder, onCompleted: toggle });
  const formOptions = useVehicleStakeholderFormOptions();
  const validationSchema = yup.object().shape(vehicleStakeholderValidationFields);
  const initialValues = vehicleStakeholderInitialValues(stakeholder, formOptions);

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} enableReinitialize onSubmit={update}>
      {formik => (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          onOpened={() => {
            reset();
            formik.handleReset();
          }}
          title="Update Vehicle Stakeholder"
          loading={loading}
          submitButtonText="Update Vehicle Stakeholder"
          submitButtonProps={{ loadingText: 'Updating...' }}
          submitButtonDisabled={formik.isSubmitting}
          onSubmit={formik.handleSubmit}
        >
          <GraphQLErrors error={error} />
          <VehicleStakeholderFormFields formOptions={formOptions} />
        </Modal>
      )}
    </Formik>
  );
}
