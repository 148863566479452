import { gql } from '@apollo/client';
import { VEHICLE_CORE_FIELDS } from '../../insuredVehicles/constants';
import { INSURED_CORE_FIELDS } from '../../insured/model';
import { usePaginatedQuery } from '../../../hooks';
import { ALL_VEHICLE_STAKEHOLDER_FIELDS } from '../../vehicleStakeholder/model';

export const LIST_VEHICLES = gql`
  query Vehicles(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $ordering: VehicleListOrder
    $status: ExposureStatus
    $searchBy: [VehicleSearchByInput] = []
    $insuredId: ID
    $makeModelYearVin: String
  ) {
    vehicles(
      first: $first
      after: $after
      last: $last
      before: $before
      ordering: $ordering
      status: $status
      searchBy: $searchBy
      insuredId: $insuredId
      makeModelYearVin: $makeModelYearVin
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          electionCount
          ...VehicleCoreFields
          insureds {
            ...insuredCoreFields
          }
          stakeholders {
            ...allVehicleStakeholderFields
          }
        }
      }
    }
  }
  ${VEHICLE_CORE_FIELDS}
  ${INSURED_CORE_FIELDS}
  ${ALL_VEHICLE_STAKEHOLDER_FIELDS}
`;

export default function useVehicles({ filters, searchBy, pageSize = 20 }) {
  const { data, loading, error, paginationProps, queryProps, fetchMore } = usePaginatedQuery(LIST_VEHICLES, {
    variables: {
      searchBy,
      first: 5,
      ...filters
    },
    pathToPageInfo: 'vehicles',
    pageSize
  });

  return {
    vehicles: data?.vehicles?.edges?.map(x => x.node),
    data,
    loading,
    error,
    paginationProps,
    queryProps,
    fetchMore
  };
}
