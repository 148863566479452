import { gql, useMutation } from '@apollo/client';
import moment from 'moment';
import { SERVER_SIDE_DATE_FORMAT } from '../../../utils/dates';

const SEND_DOCUMENT_EMAIL = gql`
  mutation send(
    $documentRelationshipId: ID
    $exposureDocumentId: ID
    $emailAddresses: [String]!
    $body: String!
    $subject: String!
    $activeOn: Date!
  ) {
    sendDocumentEmail(
      body: $body
      documentRelationshipId: $documentRelationshipId
      exposureDocumentId: $exposureDocumentId
      activeOn: $activeOn
      emailAddresses: $emailAddresses
      subject: $subject
    ) {
      id
    }
  }
`;

export default function useSendDocumentEmail({ document, onCompleted = () => {}, onError = () => {} }) {
  const [sendDoc, { loading, error }] = useMutation(SEND_DOCUMENT_EMAIL, { onCompleted, onError });

  const send = ({ emailAddresses, activeOn, ...values }) => {
    const variables = {
      documentRelationshipId: document?.documentRelationshipId,
      exposureDocumentId: document?.exposureDocumentId,
      activeOn: moment(activeOn)?.format(SERVER_SIDE_DATE_FORMAT),
      emailAddresses: emailAddresses?.map(email => email?.value),
      ...values
    };
    sendDoc({ variables });
  };

  return {
    sendEmail: send,
    loading,
    error
  };
}
