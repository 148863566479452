import { gql, useMutation } from '@apollo/client';
import { ALL_VEHICLE_STAKEHOLDER_FIELDS } from '../model';
import { GET_VEHICLE_STAKEHOLDERS } from '../../vehicleStakeholders/hooks/useVehicleStakeholders';
import { prepareValuesForSubmit } from '../../coverages/components/steps/hooks/submitPrepare';

const UPDATE_VEHICLE_STAKEHOLDER = gql`
  mutation updateVehicleStakeholder($input: UpdateVehicleStakeholderInput!) {
    updateVehicleStakeholder(input: $input) {
      ...allVehicleStakeholderFields
    }
  }
  ${ALL_VEHICLE_STAKEHOLDER_FIELDS}
`;

export default function useUpdateVehicleStakeholder({ stakeholder, onCompleted = () => {}, onError = () => {} }) {
  const [update, { loading, error, reset }] = useMutation(UPDATE_VEHICLE_STAKEHOLDER, {
    refetchQueries: [{ query: GET_VEHICLE_STAKEHOLDERS, variables: { id: stakeholder?.vehicleId } }],
    onCompleted,
    onError
  });

  const submit = values => {
    const attrs = prepareValuesForSubmit(values);
    const input = { ...attrs, vehicleId: stakeholder?.vehicleId, stakeholderId: attrs?.id };
    delete input.id;
    update({ variables: { input } });
  };

  return {
    update: submit,
    loading,
    error,
    reset
  };
}
