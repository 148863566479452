import { gql, useMutation } from '@apollo/client';

import { ALL_INSURED_STAKEHOLDER_FIELDS } from '../model';
import { GET_INSURED_STAKEHOLDERS } from './useInsuredStakeholders';
import { prepareValuesForSubmit } from '../../coverages/components/steps/hooks/submitPrepare';

const ADD_INSURED_STAKEHOLDER = gql`
  mutation addInsuredStakeholder($input: AddInsuredStakeholderInput!) {
    addInsuredStakeholder(input: $input) {
      ...allInsuredStakeholderFields
    }
  }
  ${ALL_INSURED_STAKEHOLDER_FIELDS}
`;

export default function useAddInsuredStakeholder({ insured, onCompleted = () => {}, onError = () => {} }) {
  const [add, { loading, error, reset }] = useMutation(ADD_INSURED_STAKEHOLDER, {
    refetchQueries: [{ query: GET_INSURED_STAKEHOLDERS, variables: { id: insured?.id } }],
    onCompleted,
    onError
  });

  const submit = values => {
    const attrs = prepareValuesForSubmit(values);
    const input = { ...attrs, insuredId: insured?.id };
    add({ variables: { input } });
  };

  return {
    add: submit,
    loading,
    error,
    reset
  };
}
