import { Modal } from '@oforce/global-components';
import { Formik } from 'formik';
import { GraphQLErrors } from '../../../components';
import { useSendDocumentEmail } from '../hooks';
import { DocumentEmailFields } from '.';
import * as yup from 'yup';
import moment from 'moment';
import { useSendDocumentsJobContext } from '../../../context';
import { SERVER_SIDE_DATE_FORMAT } from '../../../utils/dates';

export default function SendDocumentEmailModal({ show, toggle, document, activeOn, showActiveOn, redirectUrl }) {
  const { sendEmail, loading, error } = useSendDocumentEmail({ document, activeOn, onCompleted: toggle });
  const { startSendDocumentsJob, loading: loadingBulk, error: errorBulk } = useSendDocumentsJobContext();
  const {
    insured: { id: insuredId, email },
    documentRelationshipId,
    source,
    document: { name: documentName }
  } = document;

  const isDocumentRelationship = source === 'DOCUMENT_RELATIONSHIP';

  const validationSchema = yup.object().shape({
    emailAddresses: yup
      .array()
      .min(1)
      .of(
        yup.object().shape({
          value: yup.string().email(({ value }) => `${value} is not a valid email`)
        })
      ),
    subject: yup.string().required('Required'),
    body: yup.string().required('Required'),
    activeOn: yup.date(),
    includeStakeholders: yup.bool()
  });

  const initialEmailOptions = email ? [{ label: email, value: email }] : [];

  const initialValues = {
    emailAddresses: initialEmailOptions,
    subject: documentName,
    body: 'Download your document below.',
    activeOn: showActiveOn ? moment() : activeOn,
    includeStakeholders: true
  };

  const onSubmit = values => {
    const { activeOn, emailAddresses, includeStakeholders } = values;

    if (isDocumentRelationship && !!includeStakeholders) {
      startSendDocumentsJob({
        activeOn: moment(activeOn).format(SERVER_SIDE_DATE_FORMAT),
        emailAddresses: emailAddresses.map(email => email.value),
        includeStakeholders,
        redirectUrl,
        insuredId,
        documentRelationshipIds: [documentRelationshipId]
      });

      setTimeout(() => !errorBulk && toggle(), 1000);
    } else {
      sendEmail(values);
    }
  };

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
      {formik => (
        <Modal
          isOpen={show}
          toggle={toggle}
          title="Send Document"
          submitButtonText="Send"
          onSubmit={formik.handleSubmit}
          loading={loading || loadingBulk}
          submitButtonProps={{ loadingText: 'Sending...' }}
          onOpened={() => {
            formik.resetForm({ values: initialValues });
          }}
        >
          <GraphQLErrors error={errorBulk || error} />
          <DocumentEmailFields
            isDocumentRelationship={isDocumentRelationship}
            initialEmailOptions={initialEmailOptions}
            showActiveOn={showActiveOn}
            formik={formik}
          />
        </Modal>
      )}
    </Formik>
  );
}
