import { useState } from 'react';
import { BoxContainer, FormSection, PagerButtons, TrGraphQLErrors } from '../../../components';
import { Table } from '../../../components/table-2.0';
import { Loader, RenderData, Td, Tr, TrLoader, TrPlaceholder } from '@oforce/global-components';
import { Alert, CustomInput } from 'reactstrap';
import { formatDate } from '../../../utils/formatting';
import useInvoices from '../hooks/useInvoices';
import InvoicesSearchBy from './InvoicesSearchBy';
import { useListInvoices } from '../hooks';

export default function InvoicesSelection({ formik }) {
  const colSpan = 6;
  const [selectAll, setSelectAll] = useState(false);
  const [searchByFilters, setSearchByFilters] = useState({ searchBy: [] });
  const serviceStartDate = formik?.values?.serviceStartDate;
  const serviceEndDate = formik?.values?.serviceEndDate;

  const { invoices, loading, error, hasNextPage, hasPreviousPage, loadNextPage, loadPreviousPage } = useInvoices({
    numberToDisplay: 10,
    serviceStartDate: serviceStartDate,
    serviceEndDate: serviceEndDate,
    ...searchByFilters
  });

  const {
    data,
    loading: invoicesLoading,
    error: invoicesError
  } = useListInvoices({ serviceStartDate, serviceEndDate });

  const allValidInvoiceIds = data?.invoices?.map(invoice => invoice?.id);

  const toggleSelectAll = selectAll => {
    !selectAll ? formik?.setFieldValue('invoiceIds', allValidInvoiceIds) : formik?.setFieldValue('invoiceIds', []);
    setSelectAll(!selectAll);
  };

  const renderError = error || invoicesError;
  const renderLoading = loading || invoicesLoading;

  if (invoicesLoading) return <Loader />;

  return (
    <FormSection title="Select Invoices">
      <Alert color="info" className="mb-2">
        {`Combined invoices report will group all transactions across selected invoices by insured.`}
      </Alert>
      <div className="d-flex flex-row flex-wrap">
        <InvoicesSearchBy
          onChange={setSearchByFilters}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          toggleSelectAll={toggleSelectAll}
        />
      </div>
      <BoxContainer>
        <Table responsive>
          <thead>
            <tr>
              <th>
                <CustomInput
                  id="toggleSelectAllInvoices"
                  type="checkbox"
                  checked={selectAll}
                  onChange={() => toggleSelectAll(selectAll)}
                  className="mb-1"
                />
              </th>
              <th>Invoice ID</th>
              <th>Client Name</th>
              <th>Insurance Type</th>
              <th>Service Dates Range</th>
              <th>Policy Name</th>
            </tr>
          </thead>
          <tbody>
            <RenderData
              data={invoices}
              loading={renderLoading}
              error={renderError}
              loadingComponent={<TrLoader colSpan={colSpan} />}
              errorComponent={<TrGraphQLErrors colSpan={colSpan} error={renderError} />}
              placeholderComponent={<TrPlaceholder colSpan={colSpan}>No Invoices</TrPlaceholder>}
            >
              {invoices =>
                invoices?.map((invoice, i) => (
                  <Tr key={i}>
                    <Td>
                      <CustomInput
                        id={invoice?.id}
                        type="checkbox"
                        checked={formik?.values?.invoiceIds?.includes(invoice?.id)}
                        onChange={() => {
                          if (formik?.values?.invoiceIds?.includes(invoice?.id)) {
                            formik?.setFieldValue(
                              'invoiceIds',
                              formik?.values?.invoiceIds.filter(invoice_id => invoice_id !== invoice?.id)
                            );
                          } else {
                            formik?.setFieldValue('invoiceIds', [...formik?.values?.invoiceIds, invoice?.id]);
                          }
                        }}
                        className="mb-1"
                      />
                    </Td>
                    <Td>{invoice?.id}</Td>
                    <Td>{invoice?.client?.name}</Td>
                    <Td>{invoice?.clientProduct?.insuranceType?.name}</Td>
                    <Td>
                      {formatDate(invoice?.serviceStartDate)} – {formatDate(invoice?.serviceEndDate)}
                    </Td>
                    <Td>{invoice?.clientProduct?.name}</Td>
                  </Tr>
                ))
              }
            </RenderData>
          </tbody>
        </Table>
      </BoxContainer>
      <PagerButtons
        loading={loading}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        loadNextPage={loadNextPage}
        loadPreviousPage={loadPreviousPage}
      />
    </FormSection>
  );
}
