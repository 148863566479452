import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { MultiStepForm } from '../../components';
import { Steps } from 'react-step-builder';
import {
  BulkDownloadDocumentsDateSelection,
  BulkDownloadDocumentsDocsSelection,
  BulkDownloadDocumentsIncludeStakeholders,
  BulkDownloadDocumentsInsuredsSelection
} from './components/';
import { useListClientDocumentRelationships, useBulkDownloadDocumentsFormSteps } from './hooks';
import { useBulkDownloadDocumentsJobContext } from '../../context';
import { SERVER_SIDE_DATE_FORMAT } from '../../utils/dates';
import { useListPolicyDocumentRelationships } from '../policyDocuments/hooks';

export default function BulkDownloadDocuments({ policyId, clientId, documentIds, documents, redirectUrl }) {
  const history = useHistory();
  const { startBulkDownloadDocumentsJob, loading, error } = useBulkDownloadDocumentsJobContext();
  const isClientContext = !!clientId;

  const clientDocumentRelationshipsData = useListClientDocumentRelationships({ clientId, documentIds });
  const policyDocumentRelationshipsData = useListPolicyDocumentRelationships({ policyId, documentIds });

  const {
    allInsuredIds,
    allValidInsuredIds,
    allDocumentInsuredsMapping,
    allValidDocumentRelationships,
    loading: loadingRelationships,
    error: errorRelationships
  } = isClientContext ? clientDocumentRelationshipsData : policyDocumentRelationshipsData;

  const { formSteps } = useBulkDownloadDocumentsFormSteps(
    allInsuredIds,
    allValidInsuredIds,
    allDocumentInsuredsMapping,
    allValidDocumentRelationships,
    documentIds
  );

  return (
    <MultiStepForm
      formSteps={formSteps}
      formSubmit={values => {
        const { activeOn, includeStakeholders, documentIds, insuredIds } = values;

        const documentRelationshipIds = allValidDocumentRelationships
          ?.filter(relationship =>
            isClientContext
              ? documentIds.includes(relationship?.clientDocumentId)
              : documentIds.includes(relationship?.policyDocumentId)
          )
          ?.filter(relationship => insuredIds.includes(relationship?.insuredId))
          ?.map(relationship => relationship?.id);

        startBulkDownloadDocumentsJob({
          activeOn: activeOn === '' ? null : moment(activeOn).format(SERVER_SIDE_DATE_FORMAT),
          clientId: isClientContext ? clientId : null,
          policyId: isClientContext ? null : policyId,
          documentRelationshipIds,
          includeStakeholders,
          redirectUrl
        });
      }}
      maxWidth={'800px'}
      title={`Bulk Download Documents`}
      onCancel={() => history.push(redirectUrl)}
      submitButtonText={'Download'}
      submitButtonProps={{ loading, loadingText: 'Downloading...' }}
      showButtonsOnBottom
      loading={loadingRelationships}
      error={error || errorRelationships}
    >
      {formProps => (
        <Steps>
          <BulkDownloadDocumentsDateSelection documents={documents} {...formProps} />
          <BulkDownloadDocumentsIncludeStakeholders {...formProps} />
          <BulkDownloadDocumentsDocsSelection
            clientId={clientId}
            policyId={policyId}
            documentIds={documentIds}
            documents={documents}
            {...formProps}
          />
          <BulkDownloadDocumentsInsuredsSelection clientId={clientId} policyId={policyId} {...formProps} />
        </Steps>
      )}
    </MultiStepForm>
  );
}
