import { gql, useMutation } from '@apollo/client';
import { VEHICLE_CORE_FIELDS } from '../../insuredVehicles/constants';
import { ALL_VEHICLE_STAKEHOLDER_FIELDS } from '../../vehicleStakeholder/model';
import { prepareValuesForSubmit } from '../../coverages/components/steps/hooks/submitPrepare';

export const UPDATE_VEHICLE = gql`
  mutation UpdateVehicle($vehicleInfo: UpdateVehicleInput!) {
    vehicle: updateVehicle(input: $vehicleInfo) {
      ...VehicleCoreFields
      stakeholders {
        ...allVehicleStakeholderFields
      }
    }
  }
  ${ALL_VEHICLE_STAKEHOLDER_FIELDS}
  ${VEHICLE_CORE_FIELDS}
`;

export default function useVehicleFormSubmit({ vehicle, onCompleted = () => {}, onError = () => {} }) {
  const [updateVehicle, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_VEHICLE, {
    onCompleted: ({ vehicle }) => onCompleted(vehicle),
    onError,
    update(cache, { data }) {
      cache.writeFragment({
        id: `Vehicle:${data?.id}`,
        fragment: gql`
          fragment UpdateVehicle on Vehicle {
            ...VehicleCoreFields
            stakeholders {
              ...allVehicleStakeholderFields
            }
          }
          ${VEHICLE_CORE_FIELDS}
          ${ALL_VEHICLE_STAKEHOLDER_FIELDS}
        `,
        fragmentName: 'UpdateVehicle',
        data: { vehicle: data.vehicle }
      });
    }
  });

  function formSubmit(values) {
    const vehicleInfo = {
      id: vehicle?.id,
      vehicleMake: values?.vehicleMake,
      vehicleYear: Number(values?.vehicleYear) || null,
      vehicleModel: values?.vehicleModel,
      vin: values?.vin,
      value: values?.value || null,
      vehicleWeight: values?.vehicleWeight || null,
      vehicleTypeId: values?.vehicleType ? values?.vehicleType.value : null,
      garagedState: values?.garagedState ? values?.garagedState.value : null,
      stateOfRegistration: values?.stateOfRegistration ? values?.stateOfRegistration.value : null,
      garagedZip: values?.garagedZip,
      weightClassId: values?.weightClass ? values?.weightClass.value : null,
      commercialAutoCoverageTypeId: values?.commercialAutoCoverageType
        ? values?.commercialAutoCoverageType.value
        : null,
      mileage: values?.mileage?.toString(),
      unitNumber: values?.unitNumber.toString(),
      fleetNumber: values?.fleetNumber.toString(),
      serialNumber: values?.serialNumber.toString(),
      isTractor: values?.isTractor,
      notes: values?.notes,
      stakeholders: values?.stakeholders?.map(x => prepareValuesForSubmit(x))
    };

    updateVehicle({
      variables: { vehicleInfo }
    });
  }

  return { formSubmit, loading: updateLoading, error: updateError };
}
