import styled from 'styled-components';
import React from 'react';

import { Td, Tr } from '../../../components';
import { DocumentActions } from '.';

export default function ElectionDocumentRow({ electionDocument, exposureElectionId }) {
  const { document, insured } = electionDocument;

  return (
    <Tr>
      <Td>
        <StyledFilename>{document?.name}</StyledFilename>
      </Td>
      <Td>
        <DocumentActions
          redirectUrl={`/insureds/${insured?.id}/elections/${exposureElectionId}`}
          document={electionDocument}
          showVersions={electionDocument.source === 'DOCUMENT_RELATIONSHIP'}
        />
      </Td>
    </Tr>
  );
}

const StyledFilename = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
