import { useCanadianProvinces, useFormOptions, useUSStates } from '../../../hooks';

export default function useInsuredStakeholderFormOptions() {
  const { options: stateOptions } = useUSStates();
  const { options: canadianProvincesOptions } = useCanadianProvinces();
  const insuredStakeholderTypeOptions = [{ value: 'BENEFICIARY', laebl: 'Beneficiary' }];

  return useFormOptions({
    options: [
      { name: 'insuredStakeholderTypes', options: insuredStakeholderTypeOptions },
      { name: 'allStates', options: [...stateOptions, ...canadianProvincesOptions] }
    ]
  });
}
