import { gql } from '@apollo/client';

export const ROW_FIELDS = gql`
  fragment rowFields on ValidatedRow {
    rowId
    index
    requiredFields
    allBrokenFields
    missingFields
    invalidFields
    brokenFieldReasons {
      field
      reason
      value
    }
  }
`;

export const UPDATE_ROW_FIELDS = gql`
  fragment updateRowFields on ValidatedUpdateRow {
    rowId
    index
    requiredFields
    allBrokenFields
    missingFields
    invalidFields
    masterActiveDates {
      exposureElectionId
      masterId
      masterStartDate
      masterTerminationDate
    }
    neighborActiveDates {
      exposureElectionId
      neighborId
      neighborStartDate
      neighborTerminationDate
      location
    }
    brokenFieldReasons {
      field
      reason
      value
    }
  }
`;

export const FACTOR_VALUE_ROW_FIELDS = gql`
  fragment factorValueRowFields on ValidatedFactorValueRow {
    rowId
    index
    allBrokenFields
    invalidFields
    brokenFieldReasons {
      field
      reason
      value
    }
  }
`;

export const FACTOR_VALUE_EXPOSURE_ROW_FIELDS = gql`
  fragment exposureRowFields on ValidatedFactorValueRow {
    factorValue
    exposureElectionId
    firstName
    lastName
    vin
    businessName
    businessName2
    contractType
    masterExposureElectionId
    pricingOption
    factoredField
  }
`;

export const INSURED_ROW_FIELDS = gql`
  fragment insuredRowFields on ValidatedRow {
    firstName
    middleName
    lastName
    ssn
    dateOfBirth
    country
    address
    city
    state
    zip
    email
    mobilePhone
    businessName
    driversLicenseNumber
    driversLicenseState
    dispatchLocation
  }
`;

export const INSURED_UPDATE_ROW_FIELDS = gql`
  fragment insuredUpdateRowFields on ValidatedUpdateRow {
    firstName
    middleName
    lastName
    dateOfBirth
    country
    address
    city
    state
    zip
    email
    mobilePhone
    businessName
    businessName2
    driversLicenseNumber
    driversLicenseState
    dispatchLocation
  }
`;

export const VEHICLE_ROW_FIELDS = gql`
  fragment vehicleRowFields on ValidatedRow {
    vin
    fleetNumber
    unitNumber
    vehicleYear
    vehicleMake
    vehicleModel
    vehicleWeight
    vehicleType
    vehicleTypeId
    isTractor
    stateOfRegistration
    value
    notes
    serialNumber
    garagedState
    garagedZip
    mileage
    weightClass
    weightClassId
    commercialAutoCoverageType
    commercialAutoCoverageTypeId
  }
`;

export const VEHICLE_UPDATE_ROW_FIELDS = gql`
  fragment vehicleUpdateRowFields on ValidatedUpdateRow {
    vin
    fleetNumber
    unitNumber
    vehicleYear
    vehicleMake
    vehicleModel
    vehicleWeight
    vehicleType
    vehicleTypeId
    isTractor
    stateOfRegistration
    value
    serialNumber
    garagedState
    garagedZip
    mileage
    weightClass
    weightClassId
    commercialAutoCoverageType
    commercialAutoCoverageTypeId
  }
`;

export const EXPOSURE_ELECTION_ROW_FIELDS = gql`
  fragment exposureElectionRowFields on ValidatedRow {
    policyId
    policyPricingOptionId
    startDate
    terminationDate
    addOnIds
    masterId
    contractType
  }
`;

export const EXPOSURE_ELECTION_UPDATE_ROW_FIELDS = gql`
  fragment exposureElectionUpdateRowFields on ValidatedUpdateRow {
    terminationDate
    startDate
    exposureElectionId
  }
`;

export const VALIDATED_FACTOR_VALUE_ROW_FIELDS = gql`
  fragment validatedFactorValueRowFields on ValidatedFactorValueRow {
    ...factorValueRowFields
    ...exposureRowFields
  }
  ${FACTOR_VALUE_ROW_FIELDS}
  ${FACTOR_VALUE_EXPOSURE_ROW_FIELDS}
`;

export const VALIDATED_ROW_FIELDS = gql`
  fragment validatedRowFields on ValidatedRow {
    ...rowFields
    ...insuredRowFields
    ...vehicleRowFields
    ...exposureElectionRowFields
  }
  ${ROW_FIELDS}
  ${INSURED_ROW_FIELDS}
  ${VEHICLE_ROW_FIELDS}
  ${EXPOSURE_ELECTION_ROW_FIELDS}
`;

export const VALIDATED_UPDATE_ROW_FIELDS = gql`
  fragment validatedUpdateRowFields on ValidatedUpdateRow {
    ...updateRowFields
    ...insuredUpdateRowFields
    ...vehicleUpdateRowFields
    ...exposureElectionUpdateRowFields
  }
  ${UPDATE_ROW_FIELDS}
  ${INSURED_UPDATE_ROW_FIELDS}
  ${VEHICLE_UPDATE_ROW_FIELDS}
  ${EXPOSURE_ELECTION_UPDATE_ROW_FIELDS}
`;
