import * as yup from 'yup';

export const insuredStakeholderValidationFields = {
  id: yup.string().nullable(),
  insuredId: yup.string().nullable(),
  name: yup.string().required('Required'),
  address1: yup.string().nullable(),
  address2: yup.string().nullable(),
  city: yup.string().nullable(),
  state: yup.string().nullable(),
  zip: yup.string().nullable(),
  email: yup.string().nullable(),
  phone: yup.string().nullable(),
  relationship: yup.string().nullable(),
  types: yup.array().of(yup.object()).required('Required')
};

const findOption = (type, options) => {
  if (!!type?.value) return type;
  return options?.find(x => x.value === type);
};

export const insuredStakeholderInitialValues = (stakeholder, formOptions) => ({
  id: stakeholder?.id,
  insuredId: stakeholder?.insuredId,
  name: stakeholder?.name,
  address1: stakeholder?.address1,
  address2: stakeholder?.address2,
  city: stakeholder?.city,
  state: stakeholder?.state ? findOption(stakeholder?.state, formOptions?.allStates?.options) : null,
  zip: stakeholder?.zip,
  email: stakeholder?.email,
  phone: stakeholder?.phone,
  relationship: stakeholder?.relationship,
  types: stakeholder?.types?.map(type => findOption(type, formOptions?.insuredStakeholderTypes?.options)) || []
});

export default function step(insured, formOptions) {
  const validationSchema = yup.object({
    insuredStakeholders: yup.array().of(yup.object().shape(insuredStakeholderValidationFields))
  });

  const insuredStakeholders = insured?.stakeholders?.map(x => insuredStakeholderInitialValues(x, formOptions));

  return {
    name: 'Insured Stakeholders',
    validationSchema: validationSchema,
    initialValues: { insuredStakeholders }
  };
}
